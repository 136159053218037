/* You can add global styles to this file, and also import other style files */
@import "./app/styles/colors";
@import "./app/styles/icons";
@import "./app/styles/modal";

html {
  font-size: 16px;
}

* {
  box-sizing: border-box;
  font-family: "Muli", sans-serif;

  -webkit-touch-callout: none;
  /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;
  /* prevent webkit from resizing text to fit */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* prevent tap highlight color / shadow */
  -webkit-tap-highlight-color: transparent;
  // -webkit-user-select: none;
  /* prevent copy paste, to allow, change 'none' to 'text' */
}

.sourse_type_label {
  display: inline-block;

  &:first-letter {
    text-transform: capitalize;
  }
}

.icon-search:before {
  font-weight: bolder;
}

.code-editor * {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro", monospace !important;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    user-select: none;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  user-select: none;
}

p {
  margin: 0;
}

section:not(.linkAnnotation) {
  display: flex;
  flex-basis: 100%;
  overflow-x: hidden;
  background-color: $backgray-sidebar;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &::before,
  &::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  &.modal-open {
    overflow: hidden;
  }
}



.classroom-section .card {
  &:not(.card-children) {
    background-color: #fff;
  }

  box-sizing: border-box;
  display: flex;
  flex-basis: 100%;
  margin-bottom: 10px;
  flex-direction: column;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

//END change toggle sidebar size

* {
  scrollbar-color: #b1b4b9 #f5f5f5;
  scrollbar-width: thin;
}

// scrollbar
*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #b1b4b9;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #b1b4b9;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #b1b4b9;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(52, 58, 64, 0.55);
}

// end scrollbar

input#arrow:checked~label.arrow,
.arrow.active {
  &::before {
    transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
    -moz-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    -o-transform: rotate(-40deg);
  }

  &::after {
    transform: rotate(40deg);
    -webkit-transform: rotate(40deg);
    -moz-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    -o-transform: rotate(40deg);
  }
}

.arrow {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    content: "";
    position: relative;
    transition: transform 0.12s ease-in-out;
    -webkit-transition: transform 0.12s ease-in-out;
    -moz-transition: transform 0.12s ease-in-out;
    -ms-transition: transform 0.12s ease-in-out;
    -o-transition: transform 0.12s ease-in-out;
  }

  &::before {
    transform: rotate(40deg);
    -webkit-transform: rotate(40deg);
    -moz-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    -o-transform: rotate(40deg);
  }

  &:after {
    left: -3px;
    transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
    -moz-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    -o-transform: rotate(-40deg);
  }
}

wt-lazy {
  height: 100%;
  width: 100%;
  z-index: 7;
  position: fixed;
  top: 0;
}

.upload__title,
.upload__title * {
  font-style: normal !important;
  text-transform: none !important;
  text-decoration: none !important;

  &.note_ {
    cursor: pointer;
  }

  &.note_ * {
    font-weight: 400 !important;
  }
}

.link-a {
  color: $font-link;

  &:active {
    color: $font-link;
  }

  &:hover {
    text-decoration: underline;
  }
}

.loading-Element {
  animation: loadingElement 1s linear infinite;
  -webkit-animation: loadingElement 1s linear infinite;
}

@keyframes loadingElement {
  100% {
    opacity: 0.5;
  }
}

// Resposive app
// @function content-w-size($container, $type, $screen) {

//   @if $screen=='lg' {
//     $screen: 1440px;
//   }

//   @else if ($screen=='md') {
//     $screen: 1024px;
//   }

//   @else if ($screen=='sm') {
//     $screen: 832px;
//   }

//   @else if ($screen=='xs') {
//     $screen: 580px;
//   }


//   @media only screen and (if($type=='max', 'max-width', 'min-width'): $screen) {
//     #{$container} {
//       @content;
//     }
//   }
// }

// @include content-w-size('main-category', 'max', 'xs') {
//   font-size: 18px !important;
// }

// h3 {

// }
.dropdown-all-menu {
  &>.item {
    user-select: none;
  }

  &>.menu {
    display: none;
  }

  &>.open+.menu {
    display: block;
  }
}

// Animations loading
.loader-circle {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid transparent !important;
  // border-bottom: 2px solid transparent !important;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  padding: 8px;

  &.e-blue {
    border: 2px solid $edutin-blue;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}




.user-content {

  user-modal-dialog {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;


    .modal__container._msg_ {
      max-width: 420px;
    }
  }

}


comments-modal {
  .list {
    .items-container {
      .card {
        .card-content {
          padding: 12px 20px !important;
        }

        .lesson-info {
          display: none;
        }
      }


    }
  }


  .file_image {
    .icon-fullscreen {
      display: none;
    }
  }
}

// init component chat-room-assist -----------------
chat-room-assist {
  top: 0;
  z-index: 7;
  width: 100%;
  height: calc(100vh - 61px);
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &.over {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
  }


  modal-dialog {
    .modal__footer {
      margin: 10px 0;
      flex-direction: column;
      align-items: flex-end !important;

      .button {

        &.btn__cancel {
          margin: 6px 0;
        }

        border-radius: 40px;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        -ms-border-radius: 40px;
        -o-border-radius: 40px;
      }
    }

  }
}

// end component chat-room-assist -----------------


chat-room {
  top: 0;
  // z-index: 7;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &.over {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
  }


  modal-dialog {

    .modal {
      height: calc(100% - 120px);
    }

    .modal__footer {
      margin: 10px 0;
      flex-direction: column;
      align-items: flex-end !important;

      .button {

        &.btn__cancel {
          margin: 6px 0;
        }

        border-radius: 40px;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        -ms-border-radius: 40px;
        -o-border-radius: 40px;
      }
    }

  }

}

.live-chat-group {
  bottom: 0;
  z-index: 2;
  right: 11%;
  display: flex;
  width: fit-content;
  position: fixed;


  &.classroom {
    @media screen and (max-width:599px) {
      bottom: 51px;
    }

    @media screen and (max-width:1160px) {
      right: calc(11% + 40px);
    }

  }

  .live-chat__container {

    .live-chat__content {

      width: 100%;
      height: 100%;

      chat-room.inside {


        .chat-room {
          margin: 0;
          width: 100% !important;

          .chat-inbox {
            display: none;
          }

          .direct-msg {

            height: calc(100% + 40px);

            .direct-msg__head {
              display: none;
            }


          }


          .file-visualizer {
            .direct-msg__head {
              padding: .5rem;
              border-bottom: none;

              .profile {
                display: none;
              }
            }

            .file_prev {
              margin-top: 0;
            }
          }

        }

        .modal {
          .modal__container._msg_ {
            max-width: 90%;
          }
        }

      }
    }
  }
}

.modal__source {
  pre {
    padding: 1em;
    overflow: auto;
    cursor: default;
    background: #f5f2f0;

    code {
      font-size: 14px;
      text-align: left;
      white-space: pre;
      line-height: 1.5;
      word-wrap: normal;
      word-break: normal;
      word-spacing: normal;
      color: $dark-blue-font;
      font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    }
  }
}

.chat_group_image {
  position: relative;
  margin-right: 10px;

  &.inbox{
    margin-left: 10px;
  }
  &.conversations{
    margin-right: 5px;

    img:nth-child(2) {
      right: -8px;
    }
  }

  img {
    border: 2px solid #f6f9fc;
    $size: 30px!important;
    width: $size;
    height: $size;

    &:nth-child(2) {
      top: 6px;
      right: -12px;
      position: absolute;
    }
  }
}

// audio::-webkit-media-controls-current-time-display, video::-webkit-media-controls-current-time-display,
// audio::-webkit-media-controls-time-remaining-display, video::-webkit-media-controls-time-remaining-display {
//     -webkit-appearance: media-current-time-display;
//     -webkit-user-select: none;
//     flex: none;
//     display: flex;
//     border: none;
//     cursor: default;
//     height: 30px;
//     margin: 0 9px 10px 0;
//     // padding: 0;
//     line-height: 30px;
//     font-family: "Muli", sans-serif;
//     font-size: 14px;
//     font-weight: bold;
//     font-style: normal;
//     color: $dark-blue-font;
//     letter-spacing: normal;
//     word-spacing: normal;
//     text-transform: none;
//     text-indent: 0;
//     text-shadow: none;
//     text-decoration: none;
// }
