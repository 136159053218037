.modal {
  $size: 100%;
  width: $size;
  height: $size;
  display: flex;
  z-index: 5;
  left: 0;
  position: absolute;
  justify-content: center;
  align-items: center;
  // background-color: rgba(33, 33, 33, 0.6);

  .modal__container {

    @media screen and (min-width:501px) and (max-width:700px) {
      flex: 0 1 80%;
    }

    @media screen and (max-width:500px) {
      flex: 0 1 95%;

      & .modal__header,
      & .modal__body {
        font-size: 13px;
        line-height: 1.2;
      }

    }

    max-height: 98%;
    margin: 0 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    min-height: 90px;

    &>div {
      display: flex;
      align-items: center;
    }

    .modal__header {
      padding: .6rem 1rem;
      justify-content: flex-start;
      font-weight: bolder;
      border-bottom: .5px solid #ddd;
    }

    .modal__body {

      min-width: 200px;
      padding: .4rem 1rem;
      height: 100%;
      font-weight: 400;
      line-height: 1.6;
      overflow-y: auto;
      position: relative;
      justify-content: flex-start;

      .description {
        a {
          color: $light-blue;
          display: inline;

          &:hover {
            color: $edutin-blue;
          }
        }

        ul {
          // min-width: 380px;
          min-width: 300px;
          font-size: 14px;
          padding: 10px 15px 0 10px;

          .icon-tick {
            font-size: 11px;
            margin-right: 10px;
          }
        }
      }

      .icon-alert {
        margin-right: 8px;
        color: #f00;
        font-weight: 700;
      }

      .help__link {
        color: $light-blue;

        &:hover {
          color: $blue;
        }

      }


      .more-info {
        margin-top: 20px;
        // max-width: 335px;
        align-self: flex-start;

        .more-info__input {
          display: none;

          &:checked+label+.more-info__description {
            display: block;
          }
        }

        .more-info__label {
          color: $light-blue;
          cursor: pointer;

          &:hover {
            color: $blue;
          }
        }

        .more-info__description {
          display: none;
        }
      }


    }

    .modal__footer {
      justify-content: flex-end;
      padding: 0 10px 10px;

      .button {
        display: flex;
        padding: 8px 20px;
        font-size: .8rem;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        text-transform: uppercase;
      }
    }


    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, .16),
    0px 8px 14px 10px rgba(0, 0, 0, .09),
    0px 5px 5px -3px rgba(0, 0, 0, .14);

    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
  }
}

item-list {
  .modal {
    .modal__container {
      min-height: unset;
      font-size: 14px;
    }

    .modal__header,
    .modal__body {
      padding: 8px 1rem !important;
    }

  }
}
