// Descriptive Colors
$dark-blue-font: #031B4E;
$black: #121212;
$white: #ffffff;
$blue: #0056bf;
$light-blue: #2979FF;
$edutin-blue: #1d448e;
$red: #dc143c;
$green:#3AA337;
$green-dark:#389b35;
$gray: rgba(3, 27, 78, .5);
$font-gray: #95A2B5;
$light-gray: #f6f9fc;
$backgray-sidebar: #f3f5f9;
$border-color: #c3c9d7;
$error: rgb(220, 64, 64);

$font-blue-gray: #6d7693;
$text-color: #202d4f;
$gray-bg: #f3f4f8;
$dark-gray-bg: #dde1ec;
$color-divider: #e0e0e0;
$blue-bg: #f4f5f9;


// Functional Colors
$primary--color: $blue;
$secondary--color: $white;

$primary-font: 'Muli',
sans-serif;

// Colors App
@function font-text-v($var) {
  // var = range(0.1 - 0.9)
  @return rgba(3, 27, 78, $var);
}

$font-text: font-text-v(0.8);
$font-link: #0056BF;
