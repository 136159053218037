@font-face {
  font-family: 'edutin-icons';
  src: url('../../assets/fonts/edutin-icons.eot?gxx666');
  src: url('../../assets/fonts/edutin-icons.eot?gxx666#iefix') format('embedded-opentype'),
    url('../../assets/fonts/edutin-icons.ttf?gxx666') format('truetype'),
    url('../../assets/fonts/edutin-icons.woff?gxx666') format('woff'),
    url('../../assets/fonts/edutin-icons.svg?gxx666#edutin-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'edutin-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-form:before {
  content: "\e923";
}
.icon-course:before {
  content: "\e904";
}
.icon-book-reader:before {
  content: "\e905";
}
.icon-certification:before {
  content: "\e903";
}
.icon-suitcase:before {
  content: "\e902";
}
.icon-birthday:before {
  content: "\e984";
}
.icon-phone:before {
  content: "\e985";
}
.icon-user-circle:before {
  content: "\e986";
}
.icon-file-move:before {
  content: "\e982";
}
.icon-new-folder:before {
  content: "\e987";
}
.icon-folder-shared:before {
  content: "\e988";
}
.icon-folder-special:before {
  content: "\e98b";
}
.icon-folder:before {
  content: "\e98e";
}
.icon-folder-open:before {
  content: "\e98d";
}
.icon-mail:before {
  content: "\e98f";
}
.icon-ellipsis-h:before {
  content: "\e918";
}
.icon-ellipsis-v:before {
  content: "\e919";
}
.icon-business:before {
  content: "\e989";
}
.icon-recruitment:before {
  content: "\e98a";
}
.icon-contacts_follows:before {
  content: "\e979";
}
.icon-shopping-cart:before {
  content: "\e978";
}
.icon-filter:before {
  content: "\e976";
}
.icon-headphone:before {
  content: "\e906";
}
.icon-cam:before {
  content: "\e974";
}
.icon-mic:before {
  content: "\e975";
}
.icon-copy-outline:before {
  content: "\1f5c6";
}
.icon-new-window:before {
  content: "\e907";
}
.icon-facebook:before {
  content: "\e908";
}
.icon-github:before {
  content: "\e909";
}
.icon-google:before {
  content: "\e90b";
}
.icon-twitter:before {
  content: "\e90c";
}
.icon-www:before {
  content: "\e90d";
}
.icon-youtube:before {
  content: "\e90e";
}
.icon-arrow-point-to:before {
  content: "\e95c";
}
.icon-arrow-up-to:before {
  content: "\e90f";
}
.icon-info:before {
  content: "\e911";
}
.icon-back:before {
  content: "\e913";
}
.icon-delete:before {
  content: "\e915";
}
.icon-image:before {
  content: "\e917";
}
.icon-add-images:before {
  content: "\e97a";
}
.icon-view:before {
  content: "\e91a";
}
.icon-link:before {
  content: "\e91c";
}
.icon-volume:before {
  content: "\e91b";
}
.icon-volume-low:before {
  content: "\e93c";
}
.icon-volume-medium:before {
  content: "\e91d";
}
.icon-volume-high:before {
  content: "\e91e";
}
.icon-add-text:before {
  content: "\e91f";
}
.icon-rate:before {
  content: "\e922";
}
.icon-attached:before {
  content: "\e910";
}
.icon-arrow-up:before {
  content: "\e941";
}
.icon-arrow-down:before {
  content: "\e949";
}
.icon-arrow-left:before {
  content: "\e933";
}
.icon-arrow-right:before {
  content: "\e937";
}
.icon-next1:before {
  content: "\e926";
}
.icon-return:before {
  content: "\e928";
}
.icon-reload:before {
  content: "\e967";
}
.icon-close:before {
  content: "\e962";
}
.icon-cross:before {
  content: "\e938";
}
.icon-plus:before {
  content: "\e92c";
}
.icon-tick:before {
  content: "\e92a";
}
.icon-comments:before {
  content: "\e92b";
}
.icon-search:before {
  content: "\e930";
}
.icon-minus:before {
  content: "\e92f";
}
.icon-alarms:before {
  content: "\e92d";
}
.icon-alert:before {
  content: "\e93e";
}
.icon-cumulative-hours:before {
  content: "\e94e";
}
.icon-tick-outline:before {
  content: "\e957";
}
.icon-earth:before {
  content: "\e944";
}
.icon-cloud-error:before {
  content: "\e912";
}
.icon-lock:before {
  content: "\e945";
}
.icon-select-arrow:before {
  content: "\e947";
}
.icon-edit:before {
  content: "\e96a";
}
.icon-copy:before {
  content: "\e96b";
}
.icon-whapp-outline:before {
  content: "\e970";
}
.icon-campana-publicitaria:before {
  content: "\e983";
}
.icon-eye:before {
  content: "\e93d";
}
.icon-save:before {
  content: "\e925";
}
.icon-send:before {
  content: "\e929";
}
.icon-fullscreen:before {
  content: "\e93f";
}
.icon-question-circle:before {
  content: "\e931";
}
.icon-questions:before {
  content: "\e955";
}
.icon-circle-questions:before {
  content: "\e956";
}
.icon-follow:before {
  content: "\e94f";
}
.icon-activity:before {
  content: "\e940";
}
.icon-play-circle:before {
  content: "\e935";
}
.icon-pause-circle:before {
  content: "\e939";
}
.icon-play-circle-outline:before {
  content: "\e920";
}
.icon-underline:before {
  content: "\e932";
}
.icon-bold:before {
  content: "\e934";
}
.icon-italic:before {
  content: "\e936";
}
.icon-new-note:before {
  content: "\e93a";
}
.icon-select:before {
  content: "\e93b";
}
.icon-settings:before {
  content: "\e942";
}
.icon-download:before {
  content: "\e943";
}
.icon-evaluacion-source:before {
  content: "\e946";
}
.icon-download-video:before {
  content: "\e948";
}
.icon-back-arrow:before {
  content: "\e958";
}
.icon-next-arrow:before {
  content: "\e95b";
}
.icon-fullscreen-exit:before {
  content: "\e94a";
}
.icon-contributions:before {
  content: "\e94d";
}
.icon-home:before {
  content: "\e950";
}
.icon-notes:before {
  content: "\e952";
}
.icon-play-arrow:before {
  content: "\e927";
}
.icon-pause:before {
  content: "\e953";
}
.icon-next:before {
  content: "\e951";
}
.icon-forward:before {
  content: "\e914";
}
.icon-user:before {
  content: "\e954";
}
.icon-take-part:before {
  content: "\e959";
}
.icon-subtitles:before {
  content: "\e95a";
}
.icon-text-source:before {
  content: "\e95d";
}
.icon-video-source:before {
  content: "\e95e";
}
.icon-community:before {
  content: "\e95f";
}
.icon-certificate:before {
  content: "\e961";
}
.icon-reply:before {
  content: "\e963";
}
.icon-send-arrow:before {
  content: "\e964";
}
.icon-text:before {
  content: "\e965";
}
.icon-fbook:before {
  content: "\e969";
}
.icon-answers:before {
  content: "\e921";
}
.icon-forum:before {
  content: "\e96c";
}
.icon-message:before {
  content: "\e94b";
}
.icon-feedback:before {
  content: "\e960";
}
.icon-chat:before {
  content: "\ea0b";
}
.icon-like:before {
  content: "\e916";
}
.icon-user-add:before {
  content: "\e97b";
}
.icon-title:before {
  content: "\e96d";
}
.icon-mic1:before {
  content: "\e96f";
}
.icon-bullet:before {
  content: "\e96e";
}
.icon-content:before {
  content: "\e972";
}
.icon-arrow-to-right:before {
  content: "\e971";
}
.icon-arrow-to-up:before {
  content: "\e973";
}
.icon-search-bold:before {
  content: "\e97e";
}
.icon-archive:before {
  content: "\e98c";
}
.icon-drag:before {
  content: "\e97c";
}
.icon-drag-h:before {
  content: "\e97d";
}
.icon-code:before {
  content: "\e90a";
}
.icon-notification:before {
  content: "\e977";
}
.icon-graduation-cap:before {
  content: "\e900";
}
.icon-user-search:before {
  content: "\e901";
}